<template>
    <modal
    ref="modalMapaGoogle"
    :titulo="titulo"
    tamano="modal-xl"
    @guardar="cerrarModal"
    @cancelar="cancelar"
    >
        <div class="row">
            <div class="col-6 mb-3">
                <el-input
                v-if="busqueda"
                v-model="buscar"
                placeholder="Buscar dirección"
                autocomplete="nope"
                @change="accionBuscar"
                >
                    <i slot="suffix" class="el-input__icon el-icon-search cr-pointer" @click="accionBuscar" />
                </el-input>
            </div>
            <div class="col-4 mb-3">
                <el-input
                v-if="postal"
                v-model="buscarPostal"
                placeholder="Buscar código postal"
                autocomplete="nope"
                @change="accionBuscarPostal"
                >
                    <i slot="suffix" class="el-input__icon el-icon-search cr-pointer" @click="accionBuscarPostal" />
                </el-input>
            </div>
        </div>
        <mapa-google
        ref="GoogleMaps"
        :coordenadas="coordenadas.entrada"
        :coordenadas-entrada="coordenadas.entrada"
        :desactivar-mapa="desactivar"
        :icono-central="iconoCentral"
        height="40vh"
        :dragable="dragable"
        :buscar="buscar"
        @actualizar="actualizarCoordenadas"
        @posicion="actualizarPosicion"
        />
    </modal>
</template>

<script>
const COORDENADAS_DEFECTO = { lat: 0, lng: 0 }

export default {
    name: 'ModalGoogleMapa',
    props: {
        coordenadasEntrada: {
            type: Object,
            default: () => ({})
        },
        desactivar:{
            type: Boolean,
            default: false
        },
        busqueda:{
            type:Boolean,
            default:false
        },
        postal:{
            type:Boolean,
            default:false
        },
        iconoCentral:{
            type:String,
            default:'/img/icons/tendero-point-map.svg'
        },
        dragable:{
            type:Boolean,
            default:false
        },
        titulo:{
            type:String,
            default:'Localice su tienda'
        },
        buscar:{
            type:String,
            default:''
        },
    },
    data(){
        return {
            coordenadas: {
                entrada: COORDENADAS_DEFECTO,
                salida: COORDENADAS_DEFECTO
            },
            // buscar:'',
            buscarPostal:'',
            data_busqueda:{}
        }
    },
    watch: {
        coordenadasEntrada: {
            immediate: true,
            deep: true,
            handler(coordenadas){
                this.coordenadas.entrada =  coordenadas
                //console.log('coordenadas modal aqui', coordenadas)
            }
        },
    },
    methods: {
        toggle(estaCerrando = false){
            this.$refs.modalMapaGoogle.toggle()
            if (!estaCerrando){
                // this.coordenadas.entrada = COORDENADAS_DEFECTO
                // this.coordenadas.entrada = this.coordenadasEntrada
                this.dibujarMapa()
                this.accionBuscar(10)
            }
        },
        dibujarMapa(){
            this.$refs.GoogleMaps.dibujarMapa()
        },
        actualizarCoordenadas({ lat: latitud, lng: longitud }){
            this.coordenadas.salida = { latitud, longitud }
        },
        actualizarPosicion(data){
            this.data_busqueda = data
        },
        cerrarModal(){
            this.$emit('manual', this.data_busqueda)
            this.$emit('actualizar', this.coordenadas.salida)
            this.toggle(true)
        },
        accionBuscar(time = 500){
            if (this.buscar){
                setTimeout(() => {
                    this.$refs.GoogleMaps.buscarDireccion(this.buscar)
                }, time);
            }
        },
        accionBuscarPostal(){
            if(this.buscar && this.buscarPostal){
                this.$refs.GoogleMaps.buscarCodigoPostal(this.buscar, this.buscarPostal)
            }
        },
        cancelar(){
            this.$emit('cancelar')
        }
    }
}
</script>
