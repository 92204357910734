<template>
    <div class="menu-lateral border-right overflow-auto scroll-none menu pt-4" style="height:calc(100vh - 50px);">
        <template v-for="(item, idx) in menuSeleccionado()">
            <div v-if="$can(item.can)" :key="idx" class="row mx-0 my-1 justify-center">
                <el-tooltip class="item" effect="light" :content="item.titulo" placement="right-start">
                    <router-link
                    :data-xd="item.ruta"
                    :to="{ name: `${item.ruta}`, params: item.params || {} }"
                    :class="{'router-link-active': subIsActive(item.active)}"
                    class="d-middle-center"
                    >
                        <div v-show="item.cant>0" class="badge">
                            {{ item.cant }}
                        </div>
                        <i :class="`${item.icon} ${item.tamano ? item.tamano : 'f-20'}`" />
                    </router-link>
                </el-tooltip>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AccesosUsuario from '~/services/sistema/accesos_usuario'
export default {
    data(){
        return {
            rutaActiva: '',
            menus: {
                admin: [
                    /* { id: 7, titulo: 'Rutas', ruta: 'admin.rutas', icon: 'icon-route', tamano: 'f-24', cant: 0, can: 'menu_routing' },
                    { id: 7, titulo: 'Rutas de Entrega 2', ruta: 'admin.rutas-entrega-2', icon: 'icon-route', tamano: 'f-24', cant: 0, can: 'menu_routing' }, */
                    { id: 1, titulo: 'Cedis (Centros de Distribución)', ruta: 'admin.cedis', icon: 'icon-cedis', cant: 0, can: 'menu_cedis' },
                    { id: 2, titulo: 'Productos', ruta: 'admin.productos', icon: 'icon-package-variant-closed', cant: 0, can: 'menu_productos' },
                    { id: 3, titulo: 'Vendedores', ruta: 'admin.tiendas', icon: 'icon-leechero-cap', cant: 0, can: 'menu_leechero' },
                    { id: 16, titulo: 'Equipos', ruta:  'admin.equipos', icon: 'icon-account-group-outline', cant: 0, can: 'menu_equipos' },

                    //{ id: 20, titulo: 'Inventario vendedor', ruta: 'inventario-leechero', icon: 'icon-tienda',tamano: 'f-18', cant: 0, can: 'menu_inventario_lecheros' },


                    { id: 4, titulo: 'Pedidos', ruta: 'admin.almacen', icon: 'icon-fox', cant: 0, can: 'menu_abastecimiento' },
                    { id: 5, titulo: 'Estadisticas de Pedidos', ruta: 'admin.estadisticas', icon: 'icon-metricas', cant: 0, can: 'menu_estadisticas' },
                    { id: 11, titulo: 'Estadísticas de Cliente Final', ruta: 'admin.clientes-estadisticas', icon: 'icon-cliente', cant: 0, can: 'menu_estadisticas_clientes' },
                    { id: 6, titulo: 'Tesorería', ruta: 'admin.tesoreria', icon: 'icon-wallet-outline', cant: 0, can: 'menu_tesoreria' },
                    //{ id: 15, titulo: 'Nomina', ruta: 'admin.nomina', icon: 'icon-business', cant: 0, can: 'menu_nomina' },

                    { id: 18, titulo: 'Promociones', ruta: 'admin.promociones.grupos', icon: 'icon-sale', tamano: 'f-23', cant: 0, can: 'menu_promociones' },
                    { id: 9, titulo: 'Paginas de inicio', ruta: 'admin.paginas-inicio', icon: 'icon-grid-view', tamano: 'f-20', cant: 0, can: 'menu_landings' },
                    { id: 10, titulo: 'Cupones', ruta: 'admin.cupones', icon: 'icon-cupon', tamano: 'f-18', cant: 0, can: 'menu_cupones' },
                    //{ id: 19, titulo: 'Gaming', ruta: 'admin.gaming', icon: 'icon-controller', tamano: 'f-18', cant: 0, can: 'menu_gaming' },
                    //{ id: 21, titulo: 'Servicios de paquetería', ruta: 'servicios.paqueteria', icon: 'icon-delivery-service f-20',tamano: 'f-20', cant: 0 },
                    { id: 7, titulo: 'Entregas', ruta: 'admin.entregas', icon: 'icon-truck', tamano: 'f-24', cant: 0, can: 'menu_entregas' },
                    //{ id: 8, titulo: 'Delivery', ruta: 'admin.delivery', icon: 'icon-moped', cant: 0, can: 'menu_deliverys' },
                    //{ id: 16, titulo: 'Picking & Packing', ruta: 'admin.picking', icon: 'icon-packed', cant: 0, can: '' },
                    { id: 15, titulo: 'Proveedores', ruta: 'admin.proveedores', icon: 'icon-proveedor', tamano: 'f-18', cant: 0, can: 'menu_proveedores' },
                    //{ id: 18, titulo: 'Geocercas', ruta: 'admin.geocercas', icon: 'icon-geocerca', tamano: 'f-18', cant: 0, can: 'menu_geocercas' },
                    { id: 10, titulo: 'Publicidad', ruta: 'admin.publicidad', icon: 'icon-bullhorn-outline', tamano: 'f-22', cant: 0, can: 'menu_publicidad' },
                    //{ id: 17, titulo: 'Datos de cuentas', ruta: 'admin.datos-cuentas.clientes', icon: 'icon-date-user', cant: 0, can: 'menu_datos_cuentas' },
                    { id: 12, titulo: 'Soporte', ruta: 'admin.soporte', icon: 'icon-soporte', cant: 0, can: 'menu_soporte' },
                    { id: 13, titulo: 'Gestion de Administrador', ruta: 'admin.retiros', icon: 'icon-gestion-admin', tamano: 'f-18', cant: 0, can: 'menu_gestion_admintrador' },
                    { id: 14, titulo: 'Descuento de lista', ruta: 'admin.descuento', icon: 'icon-playlist-edit', tamano: 'f-24', cant: 0, can: 'menu_gestion_admintrador' },
                    { id: 15, titulo: 'Configuración General', ruta: 'admin.conf', icon: 'icon-configuracion', cant: 0, can: 'menu_configuracion' },

                ],
                tendero: [
                    { titulo: 'Carrito de Compras', ruta: 'tendero.carrito', icon: 'icon-cart', tamano: 'f-17', cant: 0 },
                    { titulo: 'Pedidos en proceso', ruta: 'tendero.pedidos', icon: 'icon-pedidos', cant: 0 },
                    { titulo: 'Abastecimiento', ruta: 'tendero.almacen', icon: 'icon-fox', cant: 0 },
                    { titulo: 'Reclamos', ruta: 'tendero.reclamos', icon: 'icon-alert-triangle',  cant: 0 },
                    //{ titulo: 'Productos', ruta: 'tendero.productos', icon: 'icon-package-variant-closed', tamano: 'f-25', cant: 0 },
                    //{ titulo: 'Recompensas', ruta: 'vendedor.recompensas', icon: 'icon-diamond', tamano: 'f-17', cant: 0 },
                    //{ titulo: 'Inventario', ruta: 'tendero.inventario', icon: 'icon-store', tamano: 'f-25', cant: 0 },
                    { titulo: 'Promociones', ruta: 'tendero.promociones', icon: 'icon-sale', cant: 0 },
                    { titulo: 'Descuento a Clientes', ruta: 'tendero.cupones', icon: 'icon-cupon', cant: 0, tamano: 'f-17' },
                    { titulo: 'Estadisticas', ruta: 'tendero.metricas', icon: 'icon-metricas', tamano: 'f-17',cant: 0 },
                    { titulo: 'Créditos', ruta: 'tendero.creditos', icon: 'icon-creditos', tamano: 'f-15', cant: 0 },
                    // { titulo: 'Comisión', ruta: 'tendero.comision', icon: 'icon-comision', tamano: 'f-15', cant: 0 },
                    { titulo: 'Comisión', ruta: 'tendero.comision', icon: 'icon-comision', tamano: 'f-15', cant: 0 },
                    //{ titulo: 'Alianzas', ruta: 'tendero.alianzas', icon: 'icon-price-check', tamano: 'f-15', cant: 0 },
                    { titulo: 'Ayuda', ruta: 'tendero.ayuda.frecuentes', icon: 'icon-soporte', cant: 0 },
                    { titulo: 'Configuración', ruta: 'tendero.conf', icon: 'icon-configuracion', cant: 0 },
                    /* { titulo: 'Correos electrónicos', ruta: 'tendero.correos', icon: 'icon-mail', cant: 0 }, */
                ],
            },
            showMenu: false,
            rutasExentasSoporte:['admin.soporte.tiendas', 'admin-vip.soporte.tiendas']
        }
    },
    computed: {
        ...mapGetters({
            rol: 'auth/obtenerRoles',
            user: 'auth/obtenerUsuario',
        }),
        isActive(){
            return this.$route.name
        }
    },
    mounted(){
        this.changeTitle(this.menus.admin, 11, `Estdísticas de ${this.$config.cliente} Final`);
        this.changeTitle(this.menus.admin, 3, `${this.$config.vendedor}`);
        //this.changeTitle(this.menus.admin, 20, `Inventario ${this.$config.vendedor}`);
        this.validarNominaYGaming();
    },
    methods: {
        menuSeleccionado(){
            this.actualizarAcceso()
            return this.menus[this.$usuario.rol_nombre]
        },
        changeTitle( arr , idArr, titulo){
            let nwTitle = arr.find(el => el.id == idArr);
            nwTitle.titulo = titulo;
        },
        async actualizarAcceso(){
            try {
                if(_.isNil(this.user.id)) return
                const { data } = await AccesosUsuario.guardarAccesoWeb(this.user.id,1)
            } catch (error){
                this.error_catch(error)
            }
        },
        ir(ruta){
            this.rutaActiva=ruta
            this.$router.push({ name: ruta })
        },
        subIsActive(input){
            const paths = Array.isArray(input) ? input : [input]
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0 || this.$route.path.indexOf(path) === 1 || this.$route.name === path
            })
        },
        ir_home(){
            let rol = this.$usuario.rol_nombre
            this.$router.push({name:`${rol}.home`})
        },
        notificarRutasHabilitadas(){
            if(includes){
                admin.soporte.tiendas
            }
        },
        validarNominaYGaming(){
            if(`${this.$config.menu.nomina}` == 0){
                let idxN = this.menus.admin.findIndex(el => el.id == 15);
                if(idxN > -1) this.menus.admin.splice(idxN, 1);

            }
            if(`${this.$config.menu.gaming}` == 0){
                let idxG = this.menus.admin.findIndex(el => el.id == 19);
                if(idxG > -1) this.menus.admin.splice(idxG, 1)
            }
        }
    },
    sockets:{
        chats_tiendas(value){
            try {
                const {chat} = JSON.parse(value)
                const name_rol = this.$usuario.rol_nombre
                if(parseInt(chat.created_by) === parseInt(this.$usuario.id)){ // Evitar notificar cuando el mensaje lo envia el logueado
                    return false
                }
                if(name_rol==='admin'){
                    if(this.menus[name_rol][9]){ // Modulo ayuda
                        // this.menus[name_rol][9].cant = this.menus['admin'][9].cant + 1
                    }
                    this.notifiChat(3, chat)
                }else if(name_rol==='admin-vip'){
                    if(this.menus[name_rol][8]){ // Modulo ayuda
                        // this.menus[name_rol][8].cant = this.menus[name_rol][8].cant + 1
                    }
                    this.notifiChat(3, chat)
                }


            } catch (e){
                this.error_catch(e)
            }
        },
        chats_users(value){
            try {
                const {chat} = JSON.parse(value)
                const name_rol = this.$usuario.rol_nombre
                if(parseInt(chat.created_by) === parseInt(this.$usuario.id)){ // Evitar notificar cuando el mensaje lo envia el logueado
                    return false
                }
                if(name_rol==='admin'){
                    if(this.menus[name_rol][9]){ // Modulo ayuda
                        // this.menus[name_rol][9].cant = this.menus['admin'][9].cant + 1
                    }
                    this.notifiChat(1, chat)
                }else if(name_rol==='admin-vip'){
                    if(this.menus[name_rol][8]){ // Modulo ayuda
                        // this.menus[name_rol][8].cant = this.menus[name_rol][8].cant + 1
                    }
                    this.notifiChat(1, chat)
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        admin_pedidos_chats(value){ // Emite Cuando se escribe en tabla admin_pedidos_chats
            try {
                const {chat, pedidoChat} = JSON.parse(value)
                const name_rol = this.$usuario.rol_nombre
                if(name_rol==='admin' || name_rol==='admin-vip'){
                    if(parseInt(chat.created_by) !== parseInt(this.$usuario.id)){
                        this.notifiChat(2,chat, pedidoChat)
                    }

                }else{// es tendero
                    if(this.$usuario.tienda.id === pedidoChat.id_tienda && chat.created_by !== this.$usuario.id){
                        this.notifiChat(2,chat, pedidoChat)
                    }
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        pedidos_chats(value){ // Emite Cuando se escribe en tabla admin_pedidos_chats
            try {
                const {chat, pedidoChat} = JSON.parse(value)
                const name_rol = this.$usuario.rol_nombre
                if(name_rol==='admin' || name_rol==='admin-vip'){
                    if(parseInt(chat.created_by) !== parseInt(this.$usuario.id)){
                        this.notifiChat(2,chat, pedidoChat)
                    }

                }else{// es tendero
                    if(this.$usuario.tienda.id === pedidoChat.id_tienda && chat.created_by !== this.$usuario.id){
                        this.notifiChat(2,chat, pedidoChat)
                    }
                }

            } catch (e){
                this.error_catch(e)
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.menu-lateral{
    position: fixed;
    width: 60px;
    height: 95vh;
    top: 47px;
    z-index: 0;
}
.badge{
    position: absolute;
    top: 2px;
    left: 35px;
    border-radius: 20px!important;
    border: 1px solid #fff;
    color: #fff;
    background: linear-gradient(to right, #FF9D32 5%, #FF2525)
}
.menu{
    a{
        width:40px;
        height:40px;
        border-radius: 50%;
        color: var(--text-general);
        i::before{ display: contents !important; }
        &:hover{
            transition: 0.5s;
            background: var(--color-general) !important;
            color: #FFF !important;
        }
        &.router-link-active{
            background:var(--color-general) !important;
            color: #FFF;
        }
        &:hover{
            text-decoration: none !important;
        }
    }
}
@media (max-width : 979px){
    .menu-lateral{
        display: none !important;
    }
    /* .menu-lateral{
        width: 18vw !important;
        z-index: 88;
        div{
            border-right: 1px solid #F5F5F5;
            width: 100% !important;
        }
    } */
}
</style>
