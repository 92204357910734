import Pedidos from '~/services/pedidos/pedidos-admin'
import Historial from '~/services/pedidos/pedidos-admin-historial'

export const state = {
    pedidos:[],
    paginate:{},
    id_pedido:null,
    productos:[],
    pedido:{},
    pedidoCliente:{},
    cliente:{},
    delivery:{},
    historial:[],
    resumen:{},
    cuotas:[],
    pagos:[],
    entrega:{},
    convenio: {},
    empacador: {}

}

export const getters = {
    pedidos: state => state.pedidos,
    paginate: state => state.paginate,
    id_pedido: state => state.id_pedido,
    productos: state => state.productos,
    pedido: state => state.pedido,
    pedidoCliente: state => state.pedidoCliente,
    cliente: state => state.cliente,
    delivery: state => state.delivery,
    historial: state => state.historial,
    resumen: state => state.resumen,
    cuotas: state => state.cuotas,
    pagos: state => state.pagos,
    entrega: state => state.entrega,
    convenio: state => state.convenio,
    empacador: state => state.empacador,

}

export const mutations = {

    set_pedidos: (state,payload) => {
        state.pedidos = payload
    },
    set_paginate: (state,payload) => {
        state.paginate = payload
    },
    set_id_pedido: (state,payload) => {
        state.id_pedido = null
        state.id_pedido = payload
    },
    set_productos: (state,payload) => {
        state.productos = payload
    },
    set_pedido: (state,payload) => {
        state.pedido = payload
    },
    set_pedido_cliente: (state,payload) => {
        state.pedidoCliente = payload
    },
    set_cliente: (state,payload) => {
        state.cliente = payload
    },
    set_delivery: (state,payload) => {
        state.delivery = payload
    },
    set_entrega: (state,payload) => {
        state.entrega = payload
    },
    set_convenio: (state, payload) => {
        state.convenio = payload
    },
    set_empacador: (state, payload) => {
        state.empacador = payload
    },
    set_resumenes: (state,payload) => {
        state.resumen = payload.resumen
    },
    set_historial: (state,payload) => {
        let mensajes = [
            'Un Leechero ha solicitado un pedido',
            'Pedido Confirmado',
            'Alistando Pedido',
            'Empacando Pedido',
            'Pedido Enviado',
            'Pedido Entregado',
            'Pedido Cancelado'

        ]
        let iconos = [
            'icon-receipt',
            'icon-ok-circled-outline',
            'icon-cart-arrow-down',
            'icon-shopping-outline',
            'icon-truck-outline',
            'icon-shopping',
            'icon-attention-outline',
        ]

        let history = []
        for (let idx in payload){
            if(idx == 6){
                if(payload[idx] !== null){
                    history.push({ content:mensajes[idx], hora:payload[idx], activo: payload[idx] !== null, icon:iconos[idx] })
                }
            }else{
                history.push({ content:mensajes[idx], hora:payload[idx], activo: payload[idx] !== null, icon:iconos[idx] })
            }
        }
        state.historial = history
    },
    set_credito: (state,payload) => {
        state.cuotas = payload.cuotas
        state.pagos = payload.pagos
    },
}

export const actions = {
    async listar_pedidos({commit},payload){
        const {data} = await Historial.listar_pedidos(payload)
        commit('set_paginate',data.pedidos)
        commit('set_pedidos',data.pedidos.data)
    },
    async pedidos_productos({commit},id){
        const {data} = await Pedidos.pedidos_productos(id)
        commit('set_productos',data.productos)
        commit('set_pedido',data.pedido)
        commit('set_pedido_cliente',data.pedidoCliente)
        commit('set_cliente',data.cliente)
        commit('set_delivery',data.delivery)
        commit('set_entrega',data.entrega)
        commit('set_convenio',data.convenio)
        commit('set_empacador',data.empacador)
    },
    async pedidos_historial({commit},id){
        const {data} = await Pedidos.historial_estados(id)
        commit('set_historial',data)
    },
    async pedidos_resumenes({commit},id){
        const {data} = await Pedidos.resumenes(id)
        commit('set_resumenes',data)
    },
    async pedido_credito({commit},id){
        const {data} = await Pedidos.get_credito(id)
        commit('set_credito',data)
    },
    async set_data({commit}){
        commit('set_resumenes',{resumen:{}})
        commit('set_historial',[])
        commit('set_productos',[])
        commit('set_pedido',{})
        commit('set_cliente',{})
        commit('set_id_pedido',null)
    },

}
