import axios from 'axios'

const API = 'pedidos-admin'

const Pedidos = {
    pedidos_indicadores(id_cedis){
        return axios(`${API}/indicadores?cedi=${id_cedis}`)
    },
    listar_pedidos(params){
        console.log(params);
        return axios(`${API}/listar`,{params:params})
    },
    listarChatsVigentes(params){
        console.log(params);
        return axios(`${API}/listar-chats-vigentes`,{params:params})
    },
    pedidos_productos(id_pedido){
        return axios(`${API}/${id_pedido}/ver`)
    },
    rechazar_cancelar_tendero(model){
        return axios.put(`${API}/${model.id_pedido}/rechazo-cancelacion-tendero`,model)
    },
    historial_estados(id_pedido){
        return axios(`${API}/${id_pedido}/historial-estados`)
    },
    resumenes(id_pedido){
        return axios(`${API}/${id_pedido}/resumenes`)
    },
    select_tiendas(id_cedi){
        return axios.get(`${API}/select-tiendas?cedi=${id_cedi}`)
    },
    confirmar(id_pedido){
        return axios.put(`${API}/${id_pedido}/confirmar`)
    },
    buscar_producto(params){
        return axios(`producto/buscar-simple`,{params:params})
    },
    change_producto(id_pp,model){
        return axios.post(`${API}/producto/${id_pp}/change`,model)
    },
    eliminar_producto(id_pp,model){
        return axios.put(`${API}/producto/${id_pp}/delete`,model)
    },
    cambiar_icono(model){
        return axios.put(`${API}/producto/${model.id}/cambio-icono`,model)
    },
    cambiarMasivosIconos(model){
        return axios.post(`entregas/pedidos/productos/cambiar-iconos`,model)
    },
    confirmar_cambio_estado(id_pedido,estado){
        return axios.put(`${API}/${id_pedido}/cambio-estado/${estado}`)
    },
    verificar_productos(id_pedido){
        return axios(`${API}/${id_pedido}/verificar-productos`)
    },
    nueva_direccion(id_pedido,value){
        return axios.put(`${API}/${id_pedido}/otra-direccion?value=${value}`)
    },
    get_horarios(id_cedis,fecha = null, admin = false){
        return axios(`${API}/cedis/${id_cedis}/horarios`,{params:{fecha,admin:Number(admin)}})
    },
    setFranjasHorarios(id_cedis, fecha = null, admin = false){
        return axios(`${API}/cedis/${id_cedis}/horario`,{params: {fecha, admin:Number(admin)}})
    },
    editar_pedido(id,model){
        return axios.put(`${API}/${id}/editar`,model)
    },
    crear_credito(id_pedido,model){
        return axios.post(`${API}/${id_pedido}/add-credito`,model)
    },
    get_credito(id_pedido){
        return axios.get(`${API}/${id_pedido}/credito`)
    },
    cancelar_credito(id_pedido){
        return axios.put(`${API}/${id_pedido}/credito/cancelar`)
    },
    anular_credito(id_pedido,model){
        return axios.put(`${API}/${id_pedido}/credito/anular`,model)
    },
    cambiar_fecha_masivo(model){
        return axios.put(`${API}/update/entrega/masivo`,model)
    },
    finalizar_pedido(id_pedido,model){
        return axios.put(`${API}/${id_pedido}/finalizar`,model)
    },
    enviar_pedido(id_pedido,model){
        return axios.put(`${API}/${id_pedido}/enviar`,model)
    },
    cambiar_destino_pedido(id_pedido,model){
        return axios.put(`${API}/${id_pedido}/cambio/destino`,model)
    },

    crear_pedido(model){
        return axios.post(`${API}/crear`,model)
    },
    validar_estado(id){
        return axios.get(`${API}/${id}/validar`)
    },
    finalizar_edicion_pedido_directo(id_pedido){
        return axios.put(`${API}/${id_pedido}/fin-cambios`)
    },
    devolucion(id_pedido,productos){
        return axios.put(`${API}/${id_pedido}/devolucion`,{productos})
    },
    get_delivery(id_pedido){
        return axios(`${API}/deliverys?id_pedido=${id_pedido}`)
    },
    set_delivery(id_pedido,id_user){
        return axios.put(`${API}/${id_pedido}/delivery/${id_user}/update`)
    },
    nuevo_valor_producto(id_producto,id_cedis){
        return axios(`productos/${id_producto}/cedis/${id_cedis}/precio`)
    },
    actualizar_precio_producto(id_pp,form){
        return axios.put(`${API}/producto/${id_pp}/update/precio`,form)
    },



    buscar_productos(params){ // busca productos y excluye los que ya estan en el pedido
        return axios.get(`${API}/buscar-productos`,{params})
    },
    agregar_producto(model){
        return axios.post(`${API}/agregar-producto-admin`,model)
    },
    consultar_un_pedido_admin(id_pedido_admin){
        return axios.get(`${API}/${id_pedido_admin}/listar`)
    },
    verPedido(id_pedido_admin){
        return axios.get(`${API}/${id_pedido_admin}/cambio-pedido`)
    },
    datos_sugerencia(id_sugerencia){
        return axios.get(`sugerencias/${id_sugerencia}/ver`)
    },
    rechazar_solicitud(id_sugerencia, form){
        return axios.put(`sugerencias/${id_sugerencia}/rechazar`, form)
    },
    aprobar_solicitud(id_sugerencia, form){
        return axios.put(`sugerencias/${id_sugerencia}/aprobar`, form)
    },
    historial_eliminados_o_devueltos(id_pedido,tipo){ //TIPO 1 Devolucion - 2 Eliminacion
        return axios.get(`${API}/${id_pedido}/productos/${tipo}/historial`)
    },
    datos_sugerencia(id_sugerencia){
        return axios.get(`sugerencias/${id_sugerencia}/ver`)
    },
    rechazar_solicitud(id_sugerencia, form){
        return axios.put(`sugerencias/${id_sugerencia}/rechazar`, form)
    },
    aprobar_solicitud(id_sugerencia, form){
        return axios.put(`sugerencias/${id_sugerencia}/aprobar`, form)
    },
    buscar_productos_x_cedis(params){
        return axios.get(`productos/buscador-general`,{params})
    },
    listar_pedidos_x_producto(params){
        return axios.get(`${API}/listado/x-producto`,{params})
    },
    listar_pedidos_finalizados_x_producto(params){
        return axios.get(`${API}/listado/finalizados`,{params})
    },
    listar_desconocidos(id_cedis){
        return axios.get(`${API}/listado/productos/desconocidos?id_cedis=${id_cedis}`)
    },
    select_motivos(params){
        return axios(`motivos/lista-motivos`,{params:params})
    },
    iniciar_reclamo(id_pedido,form){
        return axios.put(`pedidos-tendero/${id_pedido}/iniciar-reclamo`,form)
    },
    actualizar_pvs(form){ //masivo
        return axios.put(`${API}/${form.id_pedido}/proveedores/update`,form)
    },
    actualizar_proveedor(form){ //individual
        return axios.put(`${API}/producto/${form.id_pp}/set-proveedor`,form)
    },
    get_condiciones(id_pedido){
        return axios.get(`${API}/condiciones?id_pedido=${id_pedido}`)
    },
    set_condicion(id_pedido,id_condicion){
        return axios.put(`${API}/${id_pedido}/set-condicion/${id_condicion}/update`)
    },
    set_impuestos(id_pedido,value){
        return axios.put(`${API}/${id_pedido}/set-impuestos/${value}/update`)
    },
    selectDirecciones(id_tienda){
        return axios.get(`tiendas/${id_tienda}/select/direcciones`)
    },
    getDeudaPendiente(id_pedido){
        return axios.get(`${API}/${id_pedido}/get/deuda`)
    },
    solicitarCambioPrecio(form){
        return axios.post(`sugerencias/precios`,form)
    },
    asignarOperadorLogistico(form){
        return axios.post(`${API}/asignar/operador`,form)
    },
    desvincularOperadorLogistico(form){
        return axios.put(`${API}/desvincular/operador`,form)
    },
    putConvenioEfectivo(id, form){
        return axios.put(`${API}/${id}/actualizar/convenio/efectivo`,form)
    },
    putFormaPagoPedido(id, form){
        return axios.put(`${API}/${id}/actualizar/pedido/formapago`,form)
    },
    getEmpacadores(idCedis){
        return axios(`usuarios/cedis/${idCedis}/picking`)
    },
    setEmpacador(idPedido, idUser){
        return axios.put(`${API}/${idPedido}/picking/${idUser}/update`)
    },
    updateFechaCuotaPago(payload){
        return axios.put(`almacen-creditos/fecha`, payload)
    },

}

export default Pedidos
