import axios from 'axios'

const API = 'cart-tendero'

const Carrito = {

    categorias(params){
        return axios(`${API}/lista-categorias`,{params:params})
    },
    listar_productos(params){
        return axios(`${API}/productos`,{params:params})
    },
    agregar_producto(id_producto){
        return axios.post(`${API}/add/${id_producto}/producto`)
    },
    alter_producto(model){
        return axios.put(`${API}/change/producto`,model)
    },
    get_productos_carrito(){
        return axios(`${API}/productos-carrito`)
    },
    get_resumen(){
        return axios(`${API}/resumen`)
    },
    realizar_pedido(model){
        return axios.put(`${API}/realizar-pedido`,model)
    },
    listar_historial(params){
        return axios(`pedidos-tendero/historial`,{params:params})
    },
    agregar_desconocido(model){
        return axios.post(`${API}/producto-desconocido`,model)
    },
    eliminar_desconocido(id){
        return axios.delete(`${API}/${id}/remover`)
    },
    get_productos(id_pedido){
        return axios.get(`${API}/${id_pedido}/productos/listado`)
    },
    agregar_lista_productos(productos){
        return axios.post(`${API}/agregar/masivo`,{productos})
    },
    copiar_pedido(model){
        return axios.post(`${API}/copiar/pedido`,model)
    },
    vaciarCarrito(){
        return axios.delete(`${API}/vaciar`)
    },
    getDescuentoLista(){
        return axios.get(`select/descuento-lista`)
    },


}

export default Carrito
