export default [
    {
        path: '/admin/configurar',
        meta: { nombre: 'Configuración general' },
        name: 'admin.conf',
        component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin').then(m => m.default || m),
        children: [
            {
                path: 'tipo-tiendas',
                name: 'admin.conf.tipoTiendas',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/tipoTiendas').then(m => m.default || m),
            },
            {
                path: 'distancia',
                name: 'admin.conf.distancia',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/distancia').then(m => m.default || m),
            },
            {
                path: 'unidades',
                name: 'admin.conf.productos.unidades',
                meta: { nombre: 'Configuración general' },
                component: () => import('../../pages/configurar/admin/productos/unidadesMedida').then(m => m.default || m),
            },
            {
                path: 'peso-volumetrico',
                name: 'admin.conf.productos.peso-volumetrico',
                meta: { nombre: 'Configuración general' },
                component: () => import('../../pages/configurar/admin/pesoVolumetrico').then(m => m.default || m),
            },
            {
                path: 'motivos-movimientos',
                name: 'admin.conf.productos.motivos-movimientos',
                meta: { nombre: 'Configuración general' },
                component: () => import('../../pages/configurar/admin/motivosMovimientos/index').then(m => m.default || m),
                redirect: {name: 'admin.conf.productos.retiros-cedis'},
                children: [
                    {
                        path: 'retiros-cedis',
                        name: 'admin.conf.productos.retiros-cedis',
                        meta: { nombre: 'Configuración general' },
                        component: () => import('../../pages/configurar/admin/motivosMovimientos/retirosCedis').then(m => m.default || m),
                    },
                    {
                        path: 'traslados-cedis',
                        name: 'admin.conf.productos.traslados-cedis',
                        meta: { nombre: 'Configuración general' },
                        component: () => import('../../pages/configurar/admin/motivosMovimientos/trasladosCedis').then(m => m.default || m),
                    },
                    {
                        path: 'retiros-leecheros',
                        name: 'admin.conf.productos.retiros-leecheros',
                        meta: { nombre: 'Configuración general' },
                        component: () => import('../../pages/configurar/admin/motivosMovimientos/retirosLeecheros').then(m => m.default || m),
                    },
                    {
                        path: 'devolucion-leecheros',
                        name: 'admin.conf.productos.devolucion-leecheros',
                        meta: { nombre: 'Configuración general' },
                        component: () => import('../../pages/configurar/admin/motivosMovimientos/devolucionLeecheros').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'impuestos',
                name: 'admin.conf.productos.impuestos',
                meta: { nombre: 'Configuración general' },
                component: () => import('../../pages/configurar/admin/impuestos').then(m => m.default || m),
            },
            {
                path: 'categorias',
                name: 'admin.conf.productos.categorias',
                meta: { nombre: 'Configuración general' },
                component: () => import('../../pages/configurar/admin/productos/categorias/index').then(m => m.default || m),
            },
            {
                path: 'etiquetas',
                name: 'admin.conf.productos.etiquetas',
                meta: { nombre: 'Configuración general' },
                component: () => import('../../pages/configurar/admin/productos/etiquetas').then(m => m.default || m),
            },
            {
                path: 'tiempos',
                name: 'admin.conf.tiempos',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/tiempos').then(m => m.default || m),
            },
            {
                path: 'franjas-horarias',
                name: 'admin.conf.franjas-horarias',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/franjas').then(m => m.default || m),
            },
            {
                path: 'motivo-inactivo',
                name: 'admin.conf.motInactivo',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/motInactivo').then(m => m.default || m),
            },
            {
                path: 'criterios-calificacion',
                name: 'admin.conf.criterios',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/criterios').then(m => m.default || m),
            },
            {
                path: 'motivo-rechazo',
                name: 'admin.conf.motRechazo',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/motRechazo').then(m => m.default || m),
            },
            {
                path: 'motivo-cancelacion-tendero',
                name: 'admin.conf.motCanTendero',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/motCanTendero').then(m => m.default || m),
            },
            {
                path: 'motivo-cancelacion-cliente',
                name: 'admin.conf.motCanCliente',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/motCanCliente').then(m => m.default || m),
            },
            {
                path: 'motivo-reclamo',
                name: 'admin.conf.motReclamo',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/motReclamo').then(m => m.default || m),
            },
            {
                path: 'motivos-eliminacion',
                name: 'admin.conf.motEliminacion',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/motEliminacion').then(m => m.default || m),
            },
            {
                path: 'motivos-devolucion',
                name: 'admin.conf.motDevolucion',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/motDevolucion').then(m => m.default || m),
            },
			{
                path: 'estados-cancelacion',
                name: 'admin.conf.motCancelacion',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/cancelacionEstados').then(m => m.default || m),
            },
            {
                path: 'motivos-cambio-precio',
                name: 'admin.conf.camPrecio',
                meta: { nombre: 'Configuración general' },
                redirect: {name: 'admin.conf.camPrecio.solicitud'},
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/camPrecio').then(m => m.default || m),
                children: [
                    {
                        path: 'solicitud',
                        name: 'admin.conf.camPrecio.solicitud',
                        meta: { nombre: 'Configuración general' },
                        component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/camSolicitud').then(m => m.default || m),
                    },
                    {
                        path: 'rechazo',
                        name: 'admin.conf.camPrecio.rechazo',
                        meta: { nombre: 'Configuración general' },
                        component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/camRechazo').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'leecheros-defecto',
                name: 'admin.conf.selectLeechero',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/selectLeechero').then(m => m.default || m),
            },
            {
                path: 'tipo-registro',
                name: 'admin.conf.tipoRegistro',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/tipoRegistro').then(m => m.default || m),
            },
            {
                path: 'cedis-defecto',
                name: 'admin.conf.cedisDefecto',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/cedisDefecto').then(m => m.default || m),
            },
            {
                path: 'valor-minimo',
                name: 'admin.conf.valorMinimoPedido',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/valorMinimo').then(m => m.default || m),
            },
			{
                path: 'comisiones',
                name: 'admin.conf.comisiones',
                meta: { nombre: 'Configuración general' },
				// redirect: {name: 'admin.conf.comisiones-condiciones'},
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/indexComisiones').then(m => m.default || m),
				redirect: {name: 'admin.conf.comisiones-condiciones'},
                children: [
					{
						path: 'comisiones-condiciones',
						name: 'admin.conf.comisiones-condiciones',
						meta: { nombre: 'Configuración general' },
						component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/comisionesCondiciones').then(m => m.default || m),
					},
					{
						path: 'condiciones',
						name: 'admin.conf.condiciones',
						meta: { nombre: 'Configuración general' },
						component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/condiciones').then(m => m.default || m),
					},

                ]
            },
			{
                path: 'comisiones-vencimientos',
                name: 'admin.conf.comisiones-vencimientos',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/comisionesVencimientos').then(m => m.default || m),
            },
            {
                path: 'tipos-vendedores',
                name: 'admin.conf.tipos-vendedores',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/tiposVendedores.vue').then(m => m.default || m),
            },
            {
                path: 'tipos-clientes',
                name: 'admin.conf.tipos-clientes',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/tiposClientes').then(m => m.default || m),
            },
            {
                path: 'parametros-defecto',
                name: 'admin.conf.parametros-defecto',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/parametrosDefecto').then(m => m.default || m),
            },
            {
                path: 'home-app',
                name: 'admin.conf.home-app',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/homeApp').then(m => m.default || m),
            },
            {
                path: 'grupo-avatares',
                name: 'admin.conf.grupoAvatares',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/grupoAvatares').then(m => m.default || m),
            },
            {
                path: 'preguntas-frecuentes',
                name: 'admin.conf.preguntas',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informacion/preguntas').then(m => m.default || m),
            },
            {
                path: 'terminos',
                name: 'admin.conf.terminos',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informacion/terminos').then(m => m.default || m),
            },
            {
                path: 'politica',
                name: 'admin.conf.politica',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informacion/politica').then(m => m.default || m),
            },
            {
                path: 'motivos-novedades',
                name: 'admin.conf.motivos-novedades',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/configurar/admin/motNovedades').then(m => m.default || m),
            },
            {
                path: 'logos-sistema',
                name: 'admin.conf.logSistema',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/logoSistema').then(m => m.default || m),
            },
            /// Nuevo
            {
                path: 'imagenes-ingreso-registro',
                name: 'admin.conf.img-ingreso-registro',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/ingresoRegistro').then(m => m.default || m),
            },
            {
                path: 'imagenes-estados',
                name: 'admin.conf.logSistema.img-estados',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/imagenesEstados').then(m => m.default || m),
            },
			{
                path: 'imagenes-apps',
                name: 'admin.conf.img-apps',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/imagenesApps').then(m => m.default || m),
            },
            {
                path: 'cargando-web-movil',
                name: 'admin.conf.logSistema.img-web-movil',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/cargandoWebMovil').then(m => m.default || m),
            },
            {
                path: 'screen-splash',
                name: 'admin.conf.screen-splash',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/screenSplash').then(m => m.default || m),
            },
            /// Nuevo
            {
                path: 'factura',
                name: 'admin.conf.factura',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/factura').then(m => m.default || m),
            },
            {
                path: 'gaming',
                name: 'admin.conf.gaming',
                meta: { nombre: 'Configuración de leecheros' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/gaming').then(m => m.default || m),
            },
            {
                path: 'parametros-temporales',
                name: 'admin.conf.parametros-temporales',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/parametrosTemporales').then(m => m.default || m),
            },
            {
                path: 'forma-pago',
                name: 'admin.conf.forma-pago',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/formaPago').then(m => m.default || m),
            },
            {
                path: 'cobertura-entregas',
                name: 'admin.conf.cobertura-entregas',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/coberturaEntregas').then(m => m.default || m),
            },
            {
                path: 'horario-atencion',
                name: 'admin.conf.horario-atencion',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/horarioAtencion').then(m => m.default || m),
            },
            {
                path: 'aplicar-impuestos',
                name: 'admin.conf.aplicar-impuestos',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/aplicarImpuestos').then(m => m.default || m),
            },
            {
                path: 'impuestos-inventario',
                name: 'admin.conf.impuestos-inventario',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/impuestosInventario').then(m => m.default || m),
            },
            // {
            //     path: 'operador-logistico',
            //     name: 'admin.conf.operador-logistico',
            //     meta: { nombre: 'Configuración general' },
            //     component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/operadorLogistico').then(m => m.default || m),
            // },
            {
                path: 'dias-laborales',
                name: 'admin.conf.dias-laborales',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/diasLaborales').then(m => m.default || m),
            },
            {
                path: 'almacenar-inventario',
                name: 'admin.conf.almacenar-inventario',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/almacenarInventario').then(m => m.default || m),
            },
            {
                path: 'catalogo-cedis',
                name: 'admin.conf.catalogo-cedis',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/catalogoCedis').then(m => m.default || m),
            },
            {
                path: 'imagen-perfil',
                name: 'admin.conf.imagen-perfil',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/imagenPerfil').then(m => m.default || m),
            },
            // {
            //     path: 'estados-cargamos',
            //     name: 'admin.conf.estados-cargamos',
            //     meta: { nombre: 'Configuración general' },
            //     component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/estadosCargamos').then(m => m.default || m),
            // },
            {
                path: 'productos-stock',
                name: 'admin.conf.productos-stock',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/cediStock').then(m => m.default || m),
            },
            {
                path: 'descuentos-lista',
                name: 'admin.conf.descuentos-lista',
                meta: { nombre: 'Configuración general' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/descuentosLista').then(m => m.default || m),
            },
            {
                path: 'descuentos-cupones',
                name: 'admin.conf.vendedores.descuentos.cupones',
                meta: { nombre: 'Configuración general' },
                component: () => import('../../pages/configurar/admin/vendedores/descuentoCupon').then(m => m.default || m),
            },
        ]
    }
]
