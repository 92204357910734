export default [
    {
        path: '/descuento-lista',
        meta: { nombre: 'Descuento lista' },
        name: 'admin.descuento',
        component: () => import('../../pages/descuentosLista/pages/index.vue').then(m => m.default || m),
        redirect: {name: 'admin.descuento.productos.lista'},
        children: [
            {
                path: 'productos',
                meta: { nombre: 'Descuento lista' },
                name: 'admin.descuento.productos.lista',
                component: () => import('../../pages/descuentosLista/pages/lista').then(m => m.default || m),
            },
            {
                path: 'parametros-descuento',
                meta: { nombre: 'Descuento lista' },
                name: 'admin.descuento.parametros-descuento',
                component: () => import('../../pages/descuentosLista/pages/parametros').then(m => m.default || m),
            },
            {
                path: 'clasificacion-descuento',
                meta: { nombre: 'Descuento lista' },
                name: 'admin.descuento.clasificacion.descuento',
                component: () => import('../../pages/descuentosLista/pages/clasificacion').then(m => m.default || m),
            },
            {
                path: 'etiquetas-descuento',
                meta: { nombre: 'Descuento lista' },
                name: 'admin.descuento.etiquetas',
                component: () => import('../../pages/descuentosLista/pages/etiquetas').then(m => m.default || m),
            },
            {
                path: 'compras',
                meta: { nombre: 'Descuento lista' },
                name: 'admin.descuento.compras',
                component: () => import('../../pages/descuentosLista/pages/matriz').then(m => m.default || m),
            },
        ]
    },
]
