<template>
    <section class="vh-100 position-relative">
        <div class="w-100 h-100 position-absolute container-img">
            <div class="img-background" />
        </div>
        <div class="navbar-home">
            <div class="col-auto px-5" />
            <div class="col">
                <img src="/img/auth/logo_rojo.svg" style="max-height:75px;max-width:175px;" />
            </div>
        </div>
        <div class="container-fluid bg-white" style="height:calc(100vh - 5px);padding-top:100px;">
            <div class="row mx-0 h-100">
                <div class="col">
                    <div class="row mx-0 mt-5">
                        <div class="col">
                            <vueper-slides class="no-shadow text-muted2 h-vueper" :touchable="false" autoplay :arrows="false">
                                <vueper-slide
                                v-for="slide in slides"
                                :key="slide.id"
                                >
                                    <template v-slot:content>
                                        <div class="row mx-0 justify-center">
                                            <div class="col-auto text-center">
                                                <div class="br-12 d-middle-center shadow border py-2 content-slider" style="height:82px;width:552px;">
                                                    {{ slide.text }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </vueper-slide>
                                <template v-slot:bullet="{ active }">
                                    <div class="row mx-0">
                                        <div class="col-auto px-0">
                                            <i class="icon-brightness-1 " :class="active ? 'text-leeche' : 'text-muted'" />
                                        </div>
                                    </div>
                                </template>
                            </vueper-slides>
                        </div>
                    </div>
                    <div class="row mx-0 my-xl-4 my-lg-2 justify-center postulacion-img">
                        <div class="position-relative  col px-0" :class="estado_validacion ? 'text-right' : 'text-center'">
                            <img :src="`/img/auth/${estado_validacion ? 'cliente_registrado' : 'clientes_app'}.svg`" style="max-width:100%;height:calc(55vh - 37px);" class="obj-cover" />
                        </div>
                        <div v-if="estado_validacion" class="col">
                            <div class="shadow position-relative br-12 px-2 py-3 text-center w-100" style="max-width:280px;z-index:2">
                                <!-- Arrow -->
                                <div class="arrow-oh position-absolute" />
                                <!-- Arrow -->
                                <p class="text-leeche f-40 f-600"> !Oh Oh! </p>
                                <p class="text-muted2 f-18 mt-2">
                                    Parece que ya hay un usuario registrado con esos datos <br /> Para mayor informacion comunícate con: <br />
                                    <span class="text-leeche f-16"> contacto@leeche.com </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 justify-center">
                        <div class="col pr-0">
                            <div class="row mx-0 justify-center">
                                <router-link :to="{name: 'login.cliente.terminos-condiciones'}">
                                    <div class="col-auto f-16 text-muted2 f-600">
                                        <i class="icon-book-open-variant" /> Términos y condiciones
                                    </div>
                                </router-link>
                                <router-link :to="{name: 'login.cliente.politica-privacidad'}">
                                    <div class="col-auto f-16 text-muted2 f-600">
                                        <i class="icon-lock" /> Política de privacidad
                                    </div>
                                </router-link>
                                <router-link :to="{name: 'login.cliente.preguntas-frecuentes'}">
                                    <div class="col-auto f-16 text-muted2 f-600">
                                        <i class="icon-help" /> Preguntas frecuentes
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto d-xl-flex align-items-xl-center">
                    <div class="box custom-scroll">
                        <div class="row mx-0 my-2 justify-center">
                            <img src="/img/auth/logo_rojo.svg" style="max-width:200px;max-height:50px;" class="obj-cover" />
                        </div>
                        <div v-loading="loading" class="row mx-0 mt-5">
                            <div class="col-auto" />
                            <div class="col">
                                <ValidationObserver v-slot="{ invalid }" ref="validation">
                                    <div class="row mx-0">
                                        <div class="col d-middle-center py-2" :class="tipo_ingreso == 1 ? 'tab-active' : 'tab'" @click="tipo_ingreso = 1">
                                            <img src="/img/auth/cellphone.svg" class="obj-cover" style="max-width:100%;height:33px;" /> Telefono
                                        </div>
                                        <div class="col d-middle-center py-2" :class="tipo_ingreso == 2 ? 'tab-active' : 'tab'" @click="tipo_ingreso = 2">
                                            <img src="/img/modales/mail.svg" class="obj-cover" style="max-width:100%;height:33px;" />
                                            Correo
                                        </div>
                                    </div>
                                    <ValidationProvider v-if="tipo_ingreso == 1" v-slot="{ errors }" rules="required" vid="pais" name="pais" class="row mx-0 my-4">
                                        <div class="col">
                                            <label class="f-15 pl-3"> Selecciona el país </label>
                                            <el-select
                                            v-model="idPais"
                                            class="w-100"
                                            filterable
                                            remote
                                            reserve-keyword
                                            :remote-method="getPaises"
                                            >
                                                <el-option
                                                v-for="(item, i) in paises"
                                                :key="i"
                                                :label="item.pais"
                                                :value="item.id"
                                                />
                                            </el-select>
                                        </div>
                                        <span class="text-danger f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>

                                    <div class="row mx-0 my-4">
                                        <div class="col">
                                            <ValidationProvider v-slot="{ errors }" :rules="`required|${tipo_ingreso == 1 ? 'numeric|max:10' : 'email'}`" vid="usuario" name="usuario">
                                                <label class="text-muted2 f-15 pl-3"> {{ tipo_ingreso == 1 ? 'Número telefono' : 'Correo' }} </label>
                                                <el-input v-model="input" class="w-100 br-12">
                                                    <template v-if="tipo_ingreso == 1" slot="prepend">
                                                        <img :src="getBandera" alt="" width="25" height="25" class="rounded-circle" />
                                                        <span class="text-danger mx-1">+{{ getPrefijo }}</span>
                                                    </template>
                                                </el-input>
                                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row mx-0 justify-center">
                                        <div class="col text-muted">
                                            Enviaremos un código de seguridad al {{ tipo_ingreso == 1 ? 'número de telefono' : 'correo' }} registrado.
                                        </div>
                                        <div class="col-12 mt-3">
                                            <p class="text-muted2 f-500 text-center">
                                                También puedes registrarte con:
                                            </p>
                                        </div>
                                        <div class="col-auto cr-pointer mt-3">
                                            <img src="/img/auth/facebook.svg" class="obj-cover" style="max-width:100%;height:33px;" @click="onRegisterWithFacebook" />
                                        </div>
                                        <div class="col-auto cr-pointer mt-3" @click="onRegisterWithGoogle">
                                            <img src="/img/auth/google.svg" class="obj-cover" style="max-width:100%;height:33px;" />
                                        </div>
                                    </div>
                                    <div class="row mx-0 justify-center mt-4">
                                        <div class="col-auto">
                                            <el-checkbox v-model="checked" class="check-red" />
                                        </div>
                                        <div class="col-auto f-15">
                                            He leído y acepto los  <span class="text-general"> términos y <br /> condiciones </span>
                                        </div>
                                    </div>
                                    <div class="row mx-0 justify-center mt-4">
                                        <div class="col-8">
                                            <button
                                            :disabled="invalid || !checked"
                                            class="btn btn-block br-12 p-1 py-2 d-flex d-middle-center text-white bg-leeche border-0"
                                            @click="registrar"
                                            >
                                                Registrarme
                                            </button>
                                        </div>
                                        <div class="col-12 f-18 text-leeche text-center py-3 cr-pointer" @click="goToLogin">
                                            Ya tengo un usuario
                                        </div>
                                    </div>
                                </ValidationObserver>
                            </div>
                            <div class="col-auto" />
                        </div>
                    </div>
                </div>
                <div class="col-auto" />
                <div class="col-auto" />
            </div>
        </div>
        <!-- Partials -->
        <modal-codigo-verificacion ref="CodigoVerificacion" />
    </section>
</template>

<script>
import authService from '~/services/auth';
import firebase from '~/library/firebase';
import { mapActions, mapGetters, mapMutations } from 'vuex'
import localizacion from '~/services/localizacion';
export default {
    layout: 'login',
    components: {
        modalCodigoVerificacion: () => import('./partials/modalCodigoVerificacion' )
    },
    data(){
        return {
            input: '',
            tipo_ingreso: 1,
            checked: false,
            estado_validacion: false,
            slides: [
                {text: 'La App responde por productos defectuosos, no tendrás nada de qué preocuparte'},
                {text: 'Con la App podrás conseguir un ingreso extra por cada producto que vendas'},
                {text: 'Convierte a tus vecinos en clientes. ¡todos ganan!'},
            ],
            loading: false,
            idPais: '',
            paises: []
        }
    },
    computed: {
        getBandera(){
            const { bandera_firmada = '/img/no-imagen/default.jpg' } = this.paises.find(el => el.id == this.idPais) ?? {}
            return bandera_firmada
        },
        getPrefijo(){
            const { indicativo = '00' } = this.paises.find(el => el.id == this.idPais) ?? {}
            return indicativo
        },
    },
    async mounted(){
        await this.getPaises()
    },
    methods: {
        ...mapActions({
            intentarIniciarSesion: `auth/intentarIniciarSesion`,
            intentarIniciarSesionSocial: `auth/intentarIniciarSesionSocial`,
            registerWithGoogle: 'register/registerWithGoogle',
            registerWithFacebook: 'register/registerWithFacebook',
        }),
        ...mapMutations({
            almacenarError: `auth/ALMACENAR_ERROR`,
            removerErrores: `auth/REMOVER_ERRORES`,
        }),
        async getPaises(query = ''){
            try {
                let params = {
                    query
                }
                const {data} = await localizacion.getPais(params)
                this.paises = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async registrar(){
            try {
                const params = {
                    "usuario": this.input,
                    "indicativo": this.getPrefijo
                }
                const { data } = await authService.generarCodigoCliente(params)
                this.$store.commit('register/setIdUser', data.data.id)
                this.$store.commit('register/setIndicativo', this.getPrefijo)
                this.$refs.CodigoVerificacion.toggle(false, this.tipo_ingreso == 1);
                this.estado_validacion = !this.estado_validacion

                // console.log(data);
            } catch (error){
                this.estado_validacion = true
                this.error_catch(error, 'validation')
            }
        },
        async onRegisterWithGoogle(){
            const proveedor = new firebase.auth.GoogleAuthProvider();
            proveedor.addScope('email');
            this.abrirPopoverConexion(proveedor);
        },
        async onRegisterWithFacebook(){
            const proveedor = new firebase.auth.FacebookAuthProvider();
            proveedor.addScope('email');
            this.abrirPopoverConexion(proveedor);
        },
        async abrirPopoverConexion(proveedor){
            try {
                this.toggleLoading()
                const { credential: credenciales, user } = await firebase.auth().signInWithPopup(proveedor);
                if (!credenciales) return;

                const payload = {
                    token: credenciales.accessToken
                };
                let isGoogle = /google.com/.test(proveedor.providerId)
                if (isGoogle){
                    await this.registerWithGoogle(payload)
                } else {
                    await this.registerWithFacebook(payload)
                }
                this.$router.push({name: 'crear.perfil.cliente'})
            } catch (error){
                console.error(error);
            } finally {
                this.toggleLoading()
            }
        },
        obtenerNombreProveedor: (obj) => obj.providerData[0].providerId.split('.')[0],
        intentarIrAVerificacion(){
            if (this.existeErrorServidor || this.existeErrorSesion) return
            this.$router.push({ name: 'login.stop' })
        },
        goToLogin(){
            this.$router.push({name: 'login.form.admin'})
        },
        toggleLoading(){
            this.loading = !this.loading
        },
    }
}
</script>

<style lang="scss" scoped>
a{text-decoration: none !important;}
.navbar-home{
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    width: calc(100vw);
    height: 88px;
    background-color: #FFFFFF;
    //box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 0px 20px;
}
.box{
    width:487px;
    box-shadow:0px 3px 6px #00000080;
    /* height:calc(100vh - 311px); */
    height: auto !important;
    border-radius: 12px;
}
.tab{
    color: #5D5D5D;
    font-weight: 500px;
    border-bottom: 4px solid #F5F5F5;
    cursor: pointer;
}
@media (max-width: 1300px){
    .content-slider{
        width: 425px !important;
    }
}
@media (max-width: 1047px) {
    .content-slider{
        width: 325px !important;
    }
}
@media (max-height: 900px){
    .box{
        height:calc(100vh - 270px);
    }
}
@media (max-height: 750px){
    .box{
        height:calc(100vh - 117px) !important;
        overflow: auto;
    }
    .postulacion-img{
        img{
            height: calc(55vh - 120px) !important;
        }
    }
}
@media (max-height: 625px){
    .h-vueper{
        height: 110px;
    }
}
.arrow-oh{
    border-left: 25px solid transparent;
    width: 0px;
    height: 0px;
    border-top: 25px solid transparent;
    border-right: 25px solid white;
    left: -46px;
    top: 85px;
    z-index: 1;
    //box-shadow: 0px 3px 6px #00000029;
    border-bottom: 25px solid transparent;
    /* transform: rotate( 180deg); */
}
.img-background{
    background-image: url('/img/auth/onda_postulate.svg');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
}
.tab-active{ border-bottom: 4px solid #FF3737; cursor: pointer; }
.br-12{border-radius: 12px !important;}
</style>
