import AdminParametrizar from './parametrizar/admin'
import AdminAlmacen from './almacen/admin'
import TenderoAlmacen from './almacen/tendero'
import AdminConfigurar from './configurar/admin'
import AdminVipConfigurar from './configurar/admin-vip'
import AdminInformacion from './informacion/admin'
import AdminVipInformacion from './informacion/admin-vip'
import CalificacionesPedidos from './calificaciones/tendero-vip'
import TenderoVipInformacion from './informacion/tendero-vip'
import Promociones from './promociones/promociones'
import ProductosAdmin from './productos/admin'
import ProductosTendero from './productos/tendero'
import AyudaAdmin from './ayuda/admin'
import AyudaTendero from './ayuda/tendero'
import Soporte from './soporte/admin'
import SoporteVIP from './soporte/admin-vip'
import Login from './auth/login'
import EstadisticasAdmin from './estadisticas/admin'
import EstadisticasTendero from './estadisticas/tendero'
import EstadisticasTenderoVip from './estadisticas/tendero-vip'
import Rutas from './rutas/rutas';
import Tiendas from './tiendas/admin'
import Comision from './comisiones/comisiones';
import Delivery from './delivery/delivery';
import CorreosTendero from './correos/tendero'
import PedidosTendero from './pedidos/tendero'
import TenderoConfigurar from './configurar/tendero'
import TenderoVipConfigurar from './configurar/tendero-vip'
import Satisfaccion from './satisfaccion/satisfaccion'
import Clientes from './clientes/clientes'
import Cupones from './cupones/cupones'
import ClientesEstadisticas from './clientes/clientes-estadisticas'
import Equipos from './equipos/equipos'
import AdminVip from './satisfaccion/adminVIP'
import CentrosDistribuccion from './cedis/cedis';
import Publicidad from './publicidad/publicidad';
import Metricas from './metricas/metricas'
import MetricasAdminVip from './metricas/admin-vip'
import Proveedores from './proveedores/proveedores';
import MetricasTenderoVip from './metricas/tendero-vip'
import RegistroPostulacion from './registroPostulacion/registroPostulacion'
import Entregas from './entregas/entregas'
import InformativaLogin from './informativaLogin/informativaLogin'
import Galeria from './galeria/galeria'
import Reclamos from './reclamos/reclamos'
import Retiros from './retiros'
import TiendaEliminada from './tiendaEliminada/tendero'
import Postulaciones from './postulaciones/postulaciones'
import Recompensas from './recompensas/recompensas'
import TenderoVipCreditos from './creditos/tendero-vip'
import TenderoCreditos from './creditos/tendero'
import TesoreriaAdmin from './tesoreria/admin'
import DatosCuentas from './datosCuentas/datosCuentas';
import InventarioLeechero from './inventarioLeechero/inventarioLeechero'
import PaginasInicio from './paginasInicio/paginasInicio'
import Picking from './picking/picking'
import Geocercas from './geocercas/geocercas'
import Gaming from './gaming/gaming'
import Nomina from './nomina/nomina'
import PagosTendero from './pagos/tendero'
import serviciosPaqueteria from './serviciosPaqueteria/serviciosPaqueteria'
import comisionesLlantas from './comisiones/comisionesLlantas'
import DescuentosLista from './descuentosLista/descuentosLista'
export default [
    {
        path: "/",
        redirect: { name: 'login.form.admin' }
    },
    /* Ruta solo visible para Andres */
    {
        path: "/admin/productos/informe",
        meta: { requiresAuth: true },
        name: "general",
        component: () => import("~/pages/auth/VistaPrivada").then(m => m.default || m)
    },
    /* Ruta solo visible para Andres */
    {
        path: "/tendero/home",
        meta: { nombre: "Home" },
        name: "tendero.home",
        component: () =>
            import("../pages/home-tienda").then(m => m.default || m)
    },
    {
        path: "/tendero-vip/home",
        meta: { nombre: "Home" },
        name: "tendero-vip.home",
        component: () =>
            import("../pages/home-tienda").then(m => m.default || m)
    },
    {
        path: "/cliente/home",
        meta: { nombre: "Home" },
        name: "cliente.home",
        component: () =>
            import("../pages/homeCliente").then(m => m.default || m)
    },
    ...Login,
    ...Tiendas,
    ...Retiros,
    ...CorreosTendero,
    ...PedidosTendero,
    ...AdminParametrizar,
    ...AdminAlmacen,
    ...TenderoAlmacen,
    ...AdminConfigurar,
    ...AdminVipConfigurar,
    ...AdminInformacion,
    ...AdminVipInformacion,
    ...CalificacionesPedidos,
    ...TenderoVipInformacion,
    ...Promociones,
    ...InventarioLeechero,
    ...Entregas,
    ...Geocercas,
    // ...Comision,
    ...Cupones,
    ...ProductosAdmin,
    ...Delivery,
    ...Publicidad,
    ...ProductosTendero,
    ...AyudaAdmin,
    ...AyudaTendero,
    ...Soporte,
    ...SoporteVIP,
    ...EstadisticasAdmin,
    ...EstadisticasTendero,
    ...EstadisticasTenderoVip,
    ...TenderoConfigurar,
    ...TenderoVipConfigurar,
    ...Satisfaccion,
    ...Proveedores,
    ...Clientes,
    ...ClientesEstadisticas,
    ...DatosCuentas,
    ...AdminVip,
    ...Metricas,
    ...MetricasAdminVip,
    ...MetricasTenderoVip,
    ...RegistroPostulacion,
    ...InformativaLogin,
    ...Galeria,
    ...Reclamos,
    ...TiendaEliminada,
    ...Postulaciones,
    ...TenderoVipCreditos,
    ...Picking,
    ...TenderoCreditos,
    ...Rutas,
    ...Gaming,
    ...PaginasInicio,
    ...Nomina,
    ...CentrosDistribuccion,
    ...Recompensas,
    ...Equipos,
    ...TesoreriaAdmin,
    ...serviciosPaqueteria,
    ...comisionesLlantas,
    ...DescuentosLista,
    {
        path: "/not-found",
        name: "not-found",
        component: () => import("~/pages/notFound").then(m => m.default || m)
    },
    {
        path: '*',
        redirect: { name: 'not-found' },
    },
];
