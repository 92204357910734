import Pedidos from '~/services/pedidos/pedidos-admin'

export const state = {
    indicadores:{
        recibidos:0,
        cancelados:0,
        entregados:0,
        total_venta:0
    },
    sin_confirmar: [],
    en_reclamo: [],
    confirmados: [],
    alistados: [],
    empacados: [],
    enviados: [],
    confirmados_clientes: [],
    pendientes_confirmar: [],
    id_pedido:null,
    productos:[],
    pedido:{},
    pedidoCliente:{},
    cliente:{},
    historial:[],
    resumen:{},
    productos_stock_bajo:[],
    productos_provedores:[],
    tienda:{
        chat_clientes: 0
    },
    cuotas:[],
    pagos:[],

    fecha_filtro:[],
    tipo_filtro:0,
    cant_reclamos:0,
    entrega:{},
    convenio: {},
    empacador: {},
    metodosPago:[],
    pago_online:{},
    banderaMetodosPago:false,
	showFormOrder: false,
}

export const getters = {
    indicadores: state => state.indicadores,
    sin_confirmar: state => state.sin_confirmar,
    en_reclamo: state => state.en_reclamo,
    confirmados: state => state.confirmados,
    alistados: state => state.alistados,
    empacados: state => state.empacados,
    enviados: state => state.enviados,
    confirmados_clientes: state => state.confirmados_clientes,
    pendientes_confirmar: state => state.pendientes_confirmar,
    id_pedido: state => state.id_pedido,
    productos: state => state.productos,
    pedido: state => state.pedido,
    pedidoCliente: state => state.pedidoCliente,
    cliente: state => state.cliente,
    historial: state => state.historial,
    resumen: state => state.resumen,
    productos_stock_bajo: state => state.productos_stock_bajo,
    productos_provedores: state => state.productos_provedores,
    tienda: state => state.tienda,
    cuotas: state => state.cuotas,
    pagos: state => state.pagos,
    cant_reclamos: state => state.cant_reclamos,
    entrega: state => state.entrega,
    convenio: state => state.convenio,
    empacador: state => state.empacador,
    metodosPago: state => state.metodosPago,
    pago_online: state => state.pago_online,
    banderaMetodosPago: state => state.banderaMetodosPago,
    showFormOrder: state => state.showFormOrder,
}

export const mutations = {
    set_filtro:(state,payload) => {
        state.fecha_filtro = payload.fecha
        state.tipo_filtro = payload.tipo
    },
    set_indicadores: (state,payload) => {
        state.indicadores = {
            recibidos:payload.recibidos,
            cancelados:payload.cancelados,
            entregados:payload.entregados,
            total_venta:payload.total_venta,
        }
    },
    set_sin_confirmar: (state,payload) => {
        state.sin_confirmar = payload
    },
    set_en_reclamo: (state,payload) => {
        state.en_reclamo = payload
    },
    set_confirmados: (state,payload) => {
        state.confirmados = payload
    },
    set_alistados: (state,payload) => {
        state.alistados = payload
    },
    set_empacados: (state,payload) => {
        state.empacados = payload
    },
    set_enviados: (state,payload) => {
        state.enviados = payload
    },
    set_confirmados_clientes: (state,payload) => {
        state.confirmados_clientes = payload
    },
    set_pendientes_confirmar: (state,payload) => {
        state.pendientes_confirmar = payload
    },
    set_id_pedido: (state,payload) => {
        state.id_pedido = null
        state.id_pedido = payload
    },
    set_productos: (state,payload) => {
        state.productos = payload
    },
    set_productos_stock_bajo: (state,payload) => {

        state.productos_stock_bajo = payload
    },
    set_productos_provedores: (state,payload) => {

        state.productos_provedores = payload
    },
    set_pedido: (state,payload) => {

        state.pedido = payload
    },
    set_pedido_cliente: (state,payload) => {

        state.pedidoCliente = payload
    },
    set_cliente: (state,payload) => {
        state.cliente = payload
    },
    set_resumenes: (state,payload) => {
        state.resumen = payload.resumen
    },

    set_historial: (state,payload) => {
        let mensajes = [
            'Un Vendedor ha solicitado un pedido',
            'El pedido ha sido confirmado',
            'El pedido ha sido alistado',
            'El pedido ha sido empacado',
            'El pedido ha sido enviado',
            'El pedido ha sido entregado',
            'El pedido ha sido cancelado'
        ]
        let mensajes2 = [
            'Un Vendedor ha solicitado un pedido',
            'El pedido será confirmado',
            'El pedido será alistado',
            'El pedido será empacado',
            'El pedido será enviado',
            'El pedido será entregado',
            'El pedido ha sido cancelado'
        ]

        let iconos = [
            'icon-receipt',
            'icon-ok-circled-outline',
            'icon-cart-arrow-down',
            'icon-shopping-outline',
            'icon-truck-outline',
            'icon-shopping',
            'icon-attention-outline',
        ]


        let history = []
        for (let idx in payload){
            if(idx == 6){
                if(payload[idx] !== null){
                    history.push({ content:mensajes[idx], hora:payload[idx], activo: payload[idx] !== null, icon:iconos[idx] })
                }
            }else{
                history.push(
                    {
                        content: payload[idx] !== null ? mensajes[idx] : mensajes2[idx],
                        hora:payload[idx],
                        activo: payload[idx] !== null,
                        icon:iconos[idx]
                    }
                )
            }
        }
        state.historial = history
    },
    // Esta mutacion pasa un pedido de sin confirma a confirmado
    pasar_a_confirmado: (state,payload) => {

        let idx = state.sin_confirmar.findIndex(p=>p.id === payload.id)
        state.sin_confirmar.splice(idx, 1);

        state.confirmados.push(payload)

        state.pedido = payload
    },
    rechazar_pedido: (state,payload) => {

        let idx = state.sin_confirmar.findIndex(p=>p.id === payload)
        state.sin_confirmar.splice(idx, 1);

        state.id_pedido = null
        state.pedido = {}
        state.indicadores.cancelados++
        state.productos = []

    },

    finalizar_pedido: (state,payload) => {

        let idx = state.enviados.findIndex(p=>p.id === payload)
        state.enviados.splice(idx, 1);

        state.id_pedido = null
        state.pedido = {}
        // state.indicadores.cancelados++
        state.productos = []

    },
    push_producto: (state,payload) => {

        state.productos.unshift({icono:null,producto:{imagen:'/img/sin_datos/mercado.svg'}})

        setTimeout(function(){
            for (let [key,value] of Object.entries(payload)){
                state.productos[0][key] = value
            }
        }, 200);

    },
    set_tienda: (state,payload) => {
        state.tienda = payload
    },
    set_entrega: (state,payload) => {
        state.entrega = payload
    },
    set_convenio: (state, payload) => {
        state.convenio = payload
    },
    set_empacador: (state, payload) => {
        state.empacador = payload
    },
    set_credito: (state,payload) => {
        state.cuotas = payload.cuotas
        state.pagos = payload.pagos
    },
    delete_producto: (state,payload) => { // este se usa en entregas... es el id_pedido_producto

        let idx = state.productos.findIndex(p=>p.id === payload)
        state.productos.splice(idx, 1);
    },
    set_cant_reclamos: (state,payload) => {
        state.cant_reclamos = payload
    },
    set_metodos_pago: (state,payload) => {
        state.metodosPago = payload
        state.banderaMetodosPago = true
    },
    set_pago_online: (state,payload) => {
        if(payload){
            state.pago_online = payload.detalle_transaccion
        }else{
            state.pago_online = {}
        }
    },
    setBanderaMetodosPagos:(state,payload)=>{
        state.banderaMetodosPago = payload
    },
    setStateFormOrder:(state,payload)=>{
        state.showFormOrder = payload
    }

}

export const actions = {
    async pedidos_indicadores({commit},id_cedis){
        const {data} = await Pedidos.pedidos_indicadores(id_cedis)
        commit('set_indicadores',data.indicadores)
    },
    async listar_pedidos({state,commit},id_cedis){

        let params = {
            id_cedis:id_cedis,
            fecha:state.fecha_filtro,
            tipo:state.tipo_filtro // 0 Sin filtro - 1 Creacion -  2 Entrega
        }

        const {data} = await Pedidos.listar_pedidos(params)
        commit('set_sin_confirmar',data.sin_confirmar)
        commit('set_confirmados',data.confirmados)
        commit('set_alistados',data.alistados)
        commit('set_empacados',data.empacados)
        commit('set_enviados',data.enviados)
        commit('set_en_reclamo',data.reclamos)

        commit('set_confirmados_clientes',data.confirmados_clientes)
        commit('set_pendientes_confirmar',data.pendientes_confirmar)
    },
    async listarChatsVigentes({commit},id_cedis){

        let params = {
            id_cedis:id_cedis
        }

        const {data} = await Pedidos.listarChatsVigentes(params)
        commit('set_sin_confirmar',data.sin_confirmar)
        commit('set_en_reclamo',data.en_reclamo)
        commit('set_confirmados',data.confirmados)
        commit('set_alistados',data.alistados)
        commit('set_empacados',data.empacados)
        commit('set_enviados',data.enviados)
        commit("set_cant_reclamos", data.cant_msg_total);

    },
    async pedidos_productos({commit},id){
        const {data} = await Pedidos.pedidos_productos(id)
        commit('set_productos',data.productos)
        commit('set_pedido',data.pedido)
        commit('set_cliente',data.cliente)
    },
    async pedidos_historial({commit},id){
        const {data} = await Pedidos.historial_estados(id)
        commit('set_historial',data)
    },
    async pedidos_resumenes({commit},id){
        const {data} = await Pedidos.resumenes(id)
        commit('set_resumenes',data)
    },
    async pedido_credito({commit},id){
        const {data} = await Pedidos.get_credito(id)
        commit('set_credito',data)
    },
    async ver_pedido({commit},id){
        const {data} = await Pedidos.verPedido(id)
        commit('set_productos',data.ver.productos)
        commit('set_pedido',data.ver.pedido)
        commit('set_pedido_cliente',data.ver.pedidoCliente)
        commit('set_cliente',data.ver.cliente)
        commit('set_historial',data.historial)
        commit('set_resumenes',data.resumen)
        commit('set_entrega',data.ver.entrega)
        commit('set_convenio',data.ver.convenio)
        commit('set_empacador',data.ver.empacador)
        commit('set_metodos_pago',data.metodos_pago)
        commit('set_pago_online',data.ver.pago_online)
    },

}
