export default [
	{
        path: '/tendero/comision',
        meta: { nombre: 'Comisión' },
        name: 'tendero.comision',
		redirect: { name: 'tendero.comision.pendientes' },
        component: () => import('../../pages/comisionesLlantas/index').then(m => m.default || m),
		children:[
			{
			    path: 'pendientes',
			    meta: { nombre: 'Comisión' },
			    name: 'tendero.comision.pendientes',
			    component: () => import('../../pages/comisionesLlantas/pendientes').then(m => m.default || m),
			},
			{
			    path: 'pagadas',
			    meta: { nombre: 'Comisión' },
			    name: 'tendero.comision.pagadas',
			    component: () => import('../../pages/comisionesLlantas/pagadas').then(m => m.default || m),
			},
		]
    },
]
