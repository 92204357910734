import { render, staticRenderFns } from "./cliente.vue?vue&type=template&id=65ec140d&scoped=true"
import script from "./cliente.vue?vue&type=script&lang=js"
export * from "./cliente.vue?vue&type=script&lang=js"
import style0 from "./cliente.vue?vue&type=style&index=0&id=65ec140d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65ec140d",
  null
  
)

export default component.exports