import Almacen from '~/services/almacen/almacen'

export const state = {
    productos: [],
    query:'',
    productos_agregados:[],
    resumen:{},
    formaPago:{},
    parametros: {},
}

export const getters = {
    productos_agregados: state => state.productos_agregados,
    resumen: state => state.resumen,
    productos: state => state.productos,
    query: state => state.query,
    formaPago: state => state.formaPago,
    parametros: state => state.parametros,

}

export const mutations = {
    set_productos_agregados: (state,payload) => {
        state.productos_agregados = payload
    },
    set_resumen: (state,payload) => {
        state.resumen = payload
    },
    set_query: (state,payload) => {
        state.query = payload;
    },
    set_productos: (state,payload) => {
        state.productos.push(...payload);
    },
    vaciar_productos: (state,payload) => {

        state.productos = [];
    },
    alter_producto(state,payload){

        let index = state.productos.findIndex(p=>p.id === payload.id_producto);

        if(index < 0) return

        state.productos[index].en_pedido = payload.en_pedido
        state.productos[index].total_final = payload.total_final
        state.productos[index].total_teorico = payload.total_teorico
        state.productos[index].cantidad = payload.cantidad
    },
    alter_lista_productos(state,payload){

        let index = state.productos.findIndex(p=>p.id === payload.id);

        for (let [key,value] of Object.entries(payload)){
            state.productos[index][key] = value
        }

    },
    async setProductosConDescuentoLista(state, descuentoCliente){

        const descuentoLista = descuentoCliente.descuento
        const idLista = descuentoCliente.id_lista
        let valorDescontar = 0

        for (const key in state.productos_agregados){

            let porcentaje = state.productos_agregados[key].descuentos.find(i => i.id_desc_lista == idLista)
            const descEtiqueta = porcentaje?.valor ?? 0

            const descuento = parseFloat(descuentoLista) + parseFloat(descEtiqueta)

            valorDescontar += state.productos_agregados[key].total_final  * (descuento / 100);

            state.productos_agregados[key].total_final = state.productos_agregados[key].total_final  * (1 - descuento / 100);
        }

        const valorTotal = parseFloat(state.resumen.costo_productos) - (parseFloat(state.resumen.descuento) + parseFloat(state.resumen.val_cupon));
        //const valorDescuentoLista = parseFloat(valorTotal * (descuento / 100));
        const valorDescuentoLista = valorDescontar
        state.resumen.val_descuento_lista = valorDescuentoLista
        state.resumen.subtotal = valorTotal - valorDescuentoLista
        state.resumen.total = valorTotal - valorDescuentoLista + parseFloat(state.resumen.val_impuestos)


    },
    async alterResumenDescLista(state,descuento){ //Se dejo de usar por que ahora el descuento es diferente para cada producto

        const valorTotal = parseFloat(state.resumen.costo_productos) - (parseFloat(state.resumen.descuento) + parseFloat(state.resumen.val_cupon));
        const valorDescuentoLista = parseFloat(valorTotal * (descuento / 100));
        state.resumen.val_descuento_lista = valorDescuentoLista
        state.resumen.subtotal = valorTotal - valorDescuentoLista
        state.resumen.total = valorTotal - valorDescuentoLista + parseFloat(state.resumen.val_impuestos)


    },
    alter_agregados(state,payload){

        let index = state.productos_agregados.findIndex(p=>p.id === payload.id);

        if(index === -1){
            state.productos_agregados.unshift(payload)
            return
        }

        for (let [key,value] of Object.entries(payload)){
            // console.log(index,key,value);
            state.productos_agregados[index][key] = value
        }
    },
    remover_producto: (state,payload) => {
        let idx = state.productos_agregados.findIndex(p=>p.id_producto === payload)
        state.productos_agregados.splice(idx, 1);
    },
    remover_desconocido(state,payload){
        let idx = state.productos_agregados.findIndex(p=>p.id === payload)
        state.productos_agregados.splice(idx, 1);
    },
    agregar_desconocido(state,payload){
        state.productos_agregados.unshift(payload)
    },

    // Esta mutacion actualiza el contenido de un producto
    ordenar_productos: (state,payload) => {
        let idx = state.productos_agregados.findIndex(p=>p.id_producto === payload)
        let item = state.productos_agregados[idx];
        state.productos_agregados.splice(idx, 1);
        state.productos_agregados.unshift(item);


    },
    push_producto: (state,payload) => {
        state.productos_agregados.unshift(payload);
    },
    setFormaPago:(state,payload) => {
        state.formaPago = payload
    },
    setParametros:(state, payload) => {
        state.parametros = payload
    }
}

export const actions = {
    async get_productos_carrito({commit}){
        const {data} = await Almacen.get_productos_carrito()
        commit('set_productos_agregados',data.productos)
    },
    async get_resumen({commit}){
        const {data} = await Almacen.get_resumen()
        commit('set_resumen',data.resumen)
        commit('setParametros',data.parametros)
    },



}
